import React, { useRef, useState } from 'react';
import { Button, Card, Col, Image, Row, Spinner } from 'react-bootstrap';
import logo from '../../assets/images/Nlogo.jpg';
import { verifyCustomerOTP } from '../../actions/DashBoard/getDashBoardData';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import noImage from '../../assets/images/no-image.png';

function EnterCodeOTP() {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [userMobileNumber, setuserMobileNumber] = useState(Cookies.get("mobileNumber"));
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const otpBoxReference = useRef([]);
    const store = Cookies.get('store') ? JSON.parse(Cookies.get('store')) : {};
    const baseUrl = localStorage.getItem("baseUrl")

    const handleOTPChange = (event, index) => {
        let newArr = [...otp];
        newArr[index] = event.nativeEvent.data;
        setOtp(newArr)
        if (event.target.value && index < 6 - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    };

    // while user press back button to remove a OTP number
    const handleBackspaceAndEnter = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus()
        }
        if (e.key === "Enter" && e.target.value && index < 6 - 1) {
            otpBoxReference.current[index + 1].focus()
        }
    }

    // this function will verify user OTP, if valid then user will be navigate to home page otherwise sent a invalid OTP message
    const verifyOTP = () => {
        setLoading(true);
        let apisJson = {
            "mobileNumber": userMobileNumber,
            "otpNumber": otp ? otp.join("") : ""
        }
        dispatch(verifyCustomerOTP(apisJson)).then((res) => {
            setLoading(false);
            if (res && res.payload && res.payload.statusCode && res.payload.statusCode === 200 && res.payload.token) {
                Cookies.set("E_COMM_TOKEN", res.payload.token, { expires: 30 });
                const token = res.payload.token;
                const baseUrl = res.payload.resourceBaseUrl;
                const decoded = jwtDecode(token);
                localStorage.setItem('info', JSON.stringify(decoded));
                localStorage.setItem('baseUrl', baseUrl);
                Cookies.remove('guest');
                if (localStorage.getItem('cart') && JSON.parse(localStorage.getItem('cart')).items) {
                    navigate('/cart')
                } else {
                    navigate('/home');
                }

            } else if (res && res.payload && res.payload.error && res.payload.error === "EXPIRED_OTP") {
                alert("OTP has been expired, please enter a new otp.");
            } else {
                alert("Invalid OTP");
            }
        })
    }

    return (
        <>
            <Row className='g-0 justify-content-center position-relative bg-pages enterNumber'>
                <span className=' bg-img1'></span>
                <Col xs={12} md={12} lg={6} style={{ zIndex: '1' }}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' href='/enternumber' className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xs={12} className='d-flex align-items-center justify-content-center mb-4'>
                            <span className='bg-logo'>
                                <Image width='100%' className='logo' src={store.storeLogo ?baseUrl +  store.storeLogo : noImage} />
                            </span>
                        </Col>
                        <Col xs={12} md={12} className='mb-5'>
                            <h5 className='fs-14 fw-bold text-center'>Enter code<br />sent to your phone</h5>
                            <p className='text-center fs-12'> Kindly contact your store owner for the OTP <strong>+91 {userMobileNumber}</strong></p>
                        </Col>
                    </Row>

                    <Row className='g-0'>
                        <Col xs={12}>
                            <Card className='product-card'>
                                <Card.Body className='pt-4'>
                                    <Row className='g-0 d-flex align-items-center mb-3'>
                                        <Col xs={12} className='mb-4'>
                                            <div id="otp" className="inputs d-flex flex-row justify-content-center">
                                                {otp.map((digit, index) => (
                                                    <input key={index} value={digit} maxLength={1} type='number'
                                                        onChange={(e) => handleOTPChange(e, index)}
                                                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                                        onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                                                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                                                        className="mx-2 text-center form-control cardItem"
                                                    />
                                                ))}
                                            </div>
                                        </Col>
                                        <Col xs={12} className='text-center'>
                                            {loading ?
                                                <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <>
                                                    {otp && otp.join("").length === 6 ?
                                                        <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' onClick={verifyOTP}>
                                                            Submit
                                                        </Button> :
                                                        <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' disabled
                                                        >
                                                            Submit
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </Col>
                                        {/* <Col xs={12} md={12} className='mt-4'>
                                            <p className='text-center fs-12 mb-0'>Resend code in 00:27</p>
                                        </Col> */}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}



export default (EnterCodeOTP);
