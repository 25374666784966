import React, { useEffect, useState } from "react";
import { Card, Carousel, Col, Image, Placeholder, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import InternetVerifierModal from "../InternetVerifierModal/InternetVerifierModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import categoryImg from "../../assets/images/category.png";
import { useDispatch } from "react-redux";
import {
  getStoreListByDomain,
  getStoreMedia,
  getStoreProducts,
  loginWithGuest,
} from "../../slice/dashBoardSlice";
import { checkAuthentication, createSession } from "../../utils";
import logom from "../../assets/images/logom.png";

let bestSellerCat = null;
const WelcomePage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [storeMedia, setStoreMedia] = useState([]);

  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [bestSellingProds, setBestSellingProds] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  useEffect(() => {
    if (storeMedia) {
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    }
    getStore();
    handlePageARoute(navigator.onLine);
  }, [Cookies]);

  const getStore = async () => {
    if (!checkAuthentication()) {
      let prepareGeometry = {
        locationLat: 30.8111,
        locationLong: 75.170232,
      };
      await dispatch(loginWithGuest(prepareGeometry)).then(async (response) => {
        if (response.payload) {
          Cookies.set("E_COMM_TOKEN", response.payload.token, { expires: 30 });
          Cookies.set("authenticated", true, { expires: 30 });
          Cookies.set("guest", true, { expires: 30 });
          const baseUrl = response.payload.resourceBaseUrl;
          localStorage.setItem("baseUrl", baseUrl);
          localStorage.removeItem("cart");
          Cookies.set("location", JSON.stringify(prepareGeometry), {
            expires: 30,
          });
          createSession(response.payload.token);
          const domainName = window.location.hostname;
          await dispatch(getStoreListByDomain(domainName)).then(
            async (response) => {
              if (response.payload) {
                const currentStore =
                  Array.isArray(response.payload) && response.payload.length > 0
                    ? response.payload[0]
                    : {};
                Cookies.set("store", JSON.stringify(currentStore));
                await getStoreMediaList(currentStore.id || null);
                await getCategoryList(currentStore.id || null);
              }
            }
          );
        }
      });
    } else {
      const domainName = window.location.hostname;
      await dispatch(getStoreListByDomain(domainName)).then(
        async (response) => {
          if (response.payload) {
            const currentStore =
              Array.isArray(response.payload) && response.payload.length > 0
                ? response.payload[0]
                : {};
            Cookies.set("store", JSON.stringify(currentStore));
            await getStoreMediaList(currentStore.id || null);
            await getCategoryList(currentStore.id || null);
          }
        }
      );
    }
  };

  const getStoreMediaList = async (storeId) => {
    if (storeId) {
      await dispatch(getStoreMedia(storeId)).then((response) => {
        if (response.payload && !response.payload.error) {
          setStoreMedia(response.payload);
        }
      });
    }
  };

  const getCategoryList = async (storeId) => {
    if (storeId) {
      await dispatch(getStoreProducts(storeId)).then((response) => {
        if (response.payload) {
          const mainCatList = [];
          if (response.payload && response.payload.length > 0) {
            for (
              let catIndex = 0;
              catIndex < response.payload.length;
              catIndex++
            ) {
              if (
                response.payload[catIndex] &&
                response.payload[catIndex].categories
              ) {
                for (
                  let subCatIndex = 0;
                  subCatIndex < response.payload[catIndex].categories.length;
                  subCatIndex++
                ) {
                  if (
                    response.payload[catIndex].categories[subCatIndex] &&
                    response.payload[catIndex].categories[subCatIndex].products
                  ) {
                    const product = response.payload[catIndex].categories[
                      subCatIndex
                    ].products.find((e) => e.icon !== null);
                    if (product) {
                      mainCatList.push({
                        ...response.payload[catIndex],
                        icon: product.icon,
                      });
                      break;
                    } else {
                      mainCatList.push({
                        ...response.payload[catIndex],
                        icon: null,
                      });
                      break;
                    }
                  }
                }
              }
            }
          }
          if (mainCatList) {
            const bestSellingCat = mainCatList.find(
              (e) => e.catAlias === "Best Selling"
            );
            if (bestSellingCat && bestSellingCat.categories) {
              const prodList = [];
              bestSellingCat.categories.forEach((cat) => {
                if (cat && cat.products) {
                  cat.products.forEach((prod) => {
                    if (prod && prod.icon) {
                      prodList.push(prod);
                    }
                  });
                }
              });
              const chunkRecords = [...prodList];
              for (let i = 0; i < 3; i++) {
                if (chunkRecords.length < 3) {
                  chunkRecords.push(null);
                }
              }
              bestSellerCat = bestSellingCat;
              setBestSellingProds(chunkRecords);
            }
            const bestSelIndex = mainCatList.findIndex(
              (e) => e.catAlias === "Best Selling"
            );
            if (bestSelIndex > -1) {
              mainCatList.splice(bestSelIndex, 1);
            }
            mainCatList.unshift({ catAlias: "All", icon: null, id: "all" });

            setMainCategoryList(mainCatList);
          }
        }
      });
    }
  };

  const checkForInterConnection = () => {
    setShowModal(false);
    if (navigator.onLine) {
      return true;
    } else {
      setTimeout(() => {
        setShowModal(true);
      }, 1000);
    }
  };

  const handlePageARoute = (isInternet) => {
    if (isInternet) {
      if (Cookies.get("location")) {
        navigate("/");
      }
    } else {
      setShowModal(true);
    }
  };

  const enableCurrentLocation = () => {
    // try {
    //     if ("geolocation" in navigator) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    let prepareGeometry = {
      locationLat: 30.8111,
      locationLong: 75.170232,
    };
    if (prepareGeometry.locationLat && prepareGeometry.locationLong) {
      Cookies.set("location", JSON.stringify(prepareGeometry), { expires: 30 });
      navigate("/choosebrand");
    }
    // })
    // } else {
    //     console.log("Geolocation is not available in your browser.");
    // }
    // } catch (e) {
    //     console.log(e);
    // }
  };

  const changeMainCategory = (item) => {
    localStorage.setItem("selectedCategory", JSON.stringify(item));
    navigate("/home?categoryName=" + item.catAlias);
  };

  return (
    <>
      <Row className="g-0 justify-content-center bg-welcScreen bg-welcomePage position-relative">
        {/* <span className='bg-img1 position-absolute top-0'></span> */}
        <Col
          xs={12}
          md={12}
          lg={6}
          className="welcomepage-card-height "
          style={{ zIndex: "1" }}
        >
          <Row className="g-0 mt-4 px-2">
            <Col xs={12} md={12} className="">
              {dataLoaded === true ? (
                <Carousel className="offer_carousel " variant="dark">
                  {storeMedia.promotions &&
                    storeMedia.promotions.map((img, idx) => {
                      return (
                        <Carousel.Item
                          key={idx}
                          interval={5000}
                          className=" shadow "
                        >
                          {/* <Image className='' style={{ borderRadius: '12px' }} width="100%" height="auto" src={localStorage.getItem('baseUrl') ? localStorage.getItem('baseUrl') + img : emptyCarouselImg} /> */}

                          <div
                            className="banner-ad -danger block-3 d-flex align-items-center"
                            style={{
                              backgroundImage: `url(${
                                localStorage.getItem("baseUrl") + img
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              color: "blue",
                            }}
                          >
                            {/* <div className="banner-content align-items-center p-5">
                              <div className="content-wrapper text-light">
                                <p className="mb-0">Wedding Collection</p>
                                <h3 className="banner-title text-light">
                                  Jewellery
                                </h3>
                                <p>
                                  Discounts up to{" "}
                                  <span className="discount-p">25%</span>
                                </p>
                                <a
                                  href="#"
                                  className="btn-link text-white mt-2 d-inline-block"
                                >
                                  Shop Now
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              ) : (
                <Placeholder animation="glow" as="row" className="g-0">
                  <Placeholder
                    xs={12}
                    className=" d-block"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Placeholder>
              )}
            </Col>
            <Col xs={12} md={12} className="mt-4">
              {dataLoaded === true ? (
                <Carousel className="" variant="dark">
                  {storeMedia.cruisers &&
                    storeMedia.cruisers.map((img, idx) => {
                      return (
                        <Carousel.Item
                          interval={2000}
                          key={idx}
                          className="shadow "
                        >
                          {/* <Image className='d-block object-fit-cover' style={{ borderRadius: '12px' }} width="100%" height="200px" src={localStorage.getItem('baseUrl') ? localStorage.getItem('baseUrl') + img : emptyCarouselImg} /> */}
                          <div
                            className="banner-product -danger block-3 d-flex align-items-center shadow "
                            style={{
                              backgroundImage: `url(${
                                localStorage.getItem("baseUrl") + img
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              color: "blue",
                            }}
                          >
                            {/* <div className="banner-content align-items-center p-5">
                              <div className="content-wrapper text-light">
                                <p className="mb-0">Best Seller Collections</p>
                                <h3 className="banner-title text-light">
                                  Jewellery
                                </h3>
                                <p>
                                  Discounts up to{" "}
                                  <span className="discount-p">10%</span>
                                </p>
                                <a
                                  href="#"
                                  className="btn-link text-white mt-2 d-inline-block"
                                >
                                  Shop Now
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              ) : (
                <Placeholder animation="glow" as="row" className="g-0">
                  <Placeholder
                    xs={12}
                    className=" d-block"
                    style={{ width: "100%", height: "200px" }}
                  />
                </Placeholder>
              )}
            </Col>
          </Row>
          <Row className="g-0 p-3">
            <Col xs={12} md={12}>
              {dataLoaded === true ? (
                <Row className="g-0 w-100">
                  {mainCategoryList && mainCategoryList.length > 0
                    ? mainCategoryList.map((item, idx) => {
                        if (item.catAlias !== "All") {
                          return (
                            <>
                              <Col xs={3}>
                                <Card
                                  className="cardItem fs-12 mb-2 bg-transparent shadow-none rounded-0 w-100"
                                  role="button"
                                  onClick={() => changeMainCategory(item)}
                                  key={idx}
                                >
                                  <Card.Body className="p-2">
                                    <div className="text-center text-blue fs-12">
                                      <span className="cardImg mb-1 ">
                                        <Image
                                          width="40px"
                                          height="40px"
                                          className="rounded object-fit-cover"
                                          src={
                                            localStorage.getItem("baseUrl")
                                              ? localStorage.getItem(
                                                  "baseUrl"
                                                ) + item.icon
                                              : ""
                                          }
                                        />
                                      </span>
                                      {item.catAlias}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </>
                          );
                        } else {
                          if (item.catAlias === "All") {
                            return (
                              <>
                                <Col xs={3}>
                                  <Card
                                    className="cardItem fs-12 mb-2 bg-transparent shadow-none rounded-0 w-100"
                                    role="button"
                                    onClick={() => changeMainCategory(item)}
                                    key={idx}
                                  >
                                    <Card.Body className="p-2">
                                      <div className="text-center text-blue fs-12">
                                        <span className="cardImg mb-1 ">
                                          <Image
                                            width="20px"
                                            height="20px"
                                            className="object-fit-cover"
                                            src={categoryImg}
                                          />
                                        </span>
                                        All Category
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </>
                            );
                          }
                        }
                      })
                    : null}
                </Row>
              ) : (
                <Placeholder animation="glow" as="row" className="g-0 row">
                  {Array(12)
                    .fill(null)
                    .map((_, idx) => {
                      return (
                        <Col xs={3} className="">
                          <Card
                            className="cardItem fs-12 mb-2 bg-transparent shadow-none rounded-0 w-100"
                            key={idx}
                          >
                            <Card.Body className="p-2">
                              <Placeholder
                                xs={12}
                                className="rounded d-block mb-1 mx-auto"
                                style={{ width: "40px", height: "40px" }}
                              />
                              <Placeholder xs={12} className="d-block" />
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                </Placeholder>
              )}
            </Col>
          </Row>
          {bestSellingProds.length > 0 ? (
            <Row className="g-0 px-2 " style={{ paddingBottom: "60px" }}>
              <Col xs={12} lg={12} className="">
                <h2 className="sec-heading">Best Selling Products</h2>
              </Col>
              <Col xs={12} lg={12} className="">
                <div className="slider-container">
                  <Slider {...settings}>
                    {bestSellingProds &&
                      bestSellingProds.map((prod, index) => {
                        if (prod) {
                          return (
                            <div key={index}>
                              <Card
                                style={{ width: "100%", position: "relative" }}
                                onClick={() =>
                                  changeMainCategory(bestSellerCat)
                                }
                              >
                                <Card.Img
                                  variant="top"
                                  src={
                                    prod.icon
                                      ? localStorage.getItem("baseUrl") +
                                        prod.icon
                                      : null
                                  }
                                />

                                <Card.Title
                                  className="fs-12 text-white "
                                  style={{
                                    position: "absolute",
                                    bottom: "-8px",
                                    background: "rgb(96 18 57 / 64%)",
                                    display: "flex",
                                    width: "100%",
                                    padding: "10px 5px",
                                  }}
                                >
                                  {prod.prodAlias}
                                </Card.Title>
                              </Card>
                            </div>
                          );
                        } else {
                          return <div></div>;
                        }
                      })}
                  </Slider>
                </div>
              </Col>
            </Row>
          ) : null}

          <Row className="g-0">
            <Col
              md={12}
              className="text-center position-fixed bottom-0 start-0"
              style={{
                background: "rgb(249 243 246)",
                boxShadow: "0 .7rem 1rem rgba(0, 0, 0, .15)",
                padding: "10px 0px",
              }}
            >
              <span>
                <img style={{ width: "100px" }} src={logom} alt="logo" />
              </span>
            </Col>
          </Row>
          {/* <Row className='g-0 '>
                        <Col xs={12} lg={6} className=''>
                            <Navbar className='bg-navbar' defaultActiveKey="/" variant="" fixed="bottom">
                                <Container fluid>
                                    <Nav className="mx-auto w-100">
                                        <Row className='g-0 w-100 justify-content-center'>
                                            <Col xs={12} md={12} lg={6}>
                                                <Row className='g-0 w-100'>
                                                    <Col xs={3}>
                                                        <Nav.Link href="/home" className='' active>
                                                            <span className='d-block lh-1'><i class="fas fa-envelope"></i></span>
                                                            MSG
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Nav.Link href="/" className='text-blue'>
                                                            <span className='d-block lh-1'><i class="fas fa-list"></i></span>
                                                            Coming Soon
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Nav.Link href="/checkout" className='text-blue position-relative'>
                                                            <span className='d-block lh-1'><i class="fas fa-mobile"></i></span>
                                                            Call
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Nav.Link href="/payment" className='text-blue'>
                                                            <span className='d-block lh-1 text-green'><i class="fa-brands fa-square-whatsapp"></i></span>
                                                            WhatsApp
                                                        </Nav.Link>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Nav>
                                </Container>
                            </Navbar>
                        </Col>
                    </Row> */}
        </Col>
      </Row>

      <InternetVerifierModal
        show={showModal}
        onCloseButton={() => checkForInterConnection()}
      />
    </>
  );
};

export default WelcomePage;
