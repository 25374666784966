import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Placeholder, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeOrderStatus, fetchOrderHistory } from '../../slice/dashBoardSlice';
import noImage from '../../assets/images/no-image.png';
import CancelOrderModal from '../common/CancelOrderModal';
import noStoreImage from '../../assets/images/no_store_found.png';
import ConfirmationModal from '../common/ConfirmationModal';
import { checkAuthentication } from '../../utils';

let selectedOrder = null;
function OrderHistory() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const baseUrl = localStorage.getItem('baseUrl');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [showMessage, setShowMessage] = useState({ open: false, message: '', messageText: '', msgDescription: '', buttonText: '' });
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    useEffect(() => {
        if (checkAuthentication()) {
            getOrders();
        }else{
            navigate("/");
        }

    }, []);

    const getOrders = async () => {
        await dispatch(fetchOrderHistory()).then((response) => {
            if ((response.payload && !response.payload.error)) {
                setOrders(response.payload);
                setDataLoaded(true);
            }
        });
    }

    const orderDetails = (order) => {
        navigate('/ordersummary?orderId=' + order.id)
    }

    const iconClassName = (status) => {
        if (status === 'OPEN' || status === 'COMPLETED') {
            return 'circle-check';
        } else if (status === 'FAILED') {
            return 'circle-exclamation';
        } else if (status === 'CANCELLED') {
            return 'times-circle';
        } else {
            return '';
        }
    }

    const colorClassName = (status) => {
        if (status === 'OPEN' || status === 'COMPLETED') {
            return 'text-green';
        } else {
            return 'text-red';
        }
    }

    const formatOrderDate = (dateStr) => {
        if (!dateStr) return '';
        let splitDate = String(dateStr).split('T');
        const date = new Date(splitDate[0] + " " + (splitDate[1] ? splitDate[1].substring(0, 8) : ""));
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };

    const onCloseButton = (message) => {
        if (message = true) {
            setShowMessage({ open: false, message: "", messageText: '', msgDescription: '', buttonText: '' });
            localStorage.removeItem('orderhistory');
            navigate('/home');
        } else {
            setShowMessage({ open: false, message: "", messageText: '', msgDescription: '', buttonText: '' });
        }

    };

    const confirmCancelOrder = async (flag) => {
        if (flag === 'YES') {
            await dispatch(changeOrderStatus({ orderId: selectedOrder.id, status: "cancel" })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    setShowConfirmationDialog(false);
                    setShowMessage({ open: true, message: "SUCCESS", messageText: "Order Cancelled", msgDescription: "Your order cancelled Successfully.", buttonText: "Go To Home", });
                } else {
                    setShowMessage({ open: true, message: "FAILED", messageText: "Order Failed", msgDescription: "Please try again", buttonText: "Try Again" });
                }
            })
        } else {
            setShowConfirmationDialog(false);
        }
    };


    return (
        <>
            <Row className='g-0 justify-content-center orderHistory'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <h5 className='mb-0 w-100 pe-4 fs-6'>Order History</h5>
                            <Button size='sm' className='bg-transparent text-dark border-0'>
                                <i class="fas fa-close" onClick={() => navigate('/home')}></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <>
                                {orders && orders.map((itm, idx) => {
                                    return (
                                        <>
                                            <Col key={idx} xs={12}>
                                                <Card className='cardItem mb-2 position-relative' >
                                                    <Button size='sm' className='text-dark border-0 ms-3 mb-3 position-absolute top-0 end-0 ' style={{ background: '#d7d7d7', borderEndStartRadius: '10px', borderEndEndRadius: '0px', borderTopLeftRadius: '0px', borderTopRightRadius: '10px' }}>
                                                        <i class='fas fa-download'></i>
                                                    </Button>
                                                    <Card.Header className='p-2 border-bottom bg-light text-decoration-none text-dark' onClick={() => navigate('/home')} role='button'>
                                                        <Row className='g-0 fs-14'>
                                                            <Col xs={12} className='d-flex align-items-start align-items-md-center'>
                                                                <div className=''>
                                                                    <Button variant='primary' className='light-btn brand_img me-3' >
                                                                        <Image className='' src={itm?.store?.storeLogo ? baseUrl + itm?.store?.storeLogo : noStoreImage} />
                                                                    </Button>
                                                                </div>
                                                                {/* <Image width='50px' height='50px' className='me-3 rounded' src={itm?.store?.storeLogo ? baseUrl + "/" + itm?.store?.storeLogo : noStoreImage} /> */}
                                                                <div className='w-100 align-self-center'>
                                                                    <h4 className="fs-14 fw-bold mb-0 DroidSerif">{itm?.store?.name}</h4>
                                                                    <div className='d-flex flex-wrap'>
                                                                        <span className="fs-12 me-auto pe-3 text-blue">
                                                                            <span className=''><i class="fas fa-location-dot me-1"></i>{itm?.store?.address}</span>
                                                                            <div>Order Number: {itm.tempInvoiceNo}</div>
                                                                            {/* <div className='divider'></div>
                                                                            <span className=''>{itm.mins}</span> */}
                                                                        </span>
                                                                        {/* <span className='fs-12 text-blue ms-auto'> View Menu  </span> */}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body className='p-2 text-decoration-none' onClick={() => orderDetails(itm)} role='button'>
                                                        <Row className='g-0 fs-14'>
                                                            <Col xs={12} className=''>
                                                                <ul className='list-group mb-2 fs-12'>
                                                                    {itm.items && itm.items.map((item, idx) => {
                                                                        return (
                                                                            <>
                                                                                <li key={idx} className="list-group-item border-0 p-1 ps-0">
                                                                                    <Image width='14px' className='' style={{ borderRadius: 4 }} src={item.icon ? baseUrl + item.icon : noImage} />
                                                                                    <span className='mx-1 text-blue fs-12'>{item.qty} *</span>{item.prodAlias}
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    )}
                                                                </ul>
                                                                <ul className='list-group list-group-flush' style={{ borderTop: '1px dashed #dee2e6' }}>
                                                                    <li className="list-group-item px-0">
                                                                        <div className='d-flex flex-wrap align-items-end' >
                                                                            <span className="fs-12 me-auto pe-3 text-blue">
                                                                                <div>Order {itm.orderStage}</div>
                                                                                <div>{formatOrderDate(itm.orderDate)}</div>
                                                                            </span>
                                                                            <span className='fs-12 fw-bold ms-auto'><i class="fas fa-indian-rupee-sign fs-11 text-blue"></i>{itm.orderTotal}</span>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item px-0 pb-0">
                                                                        <div className='d-flex flex-wrap align-items-center'>
                                                                            <span className={`fs-12 me-auto pe-3 ${colorClassName(itm.status)}`}>
                                                                                <i class={`fas fa-${iconClassName(itm.status)} me-1`}></i>{itm.status === 'OPEN' ? 'PLACED' : itm.status}
                                                                            </span>
                                                                            <span className="fs-12 ms-auto text-blue" onClick={() => orderDetails(itm)}>
                                                                                More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>

                                                    {itm.status === "OPEN" ?
                                                        <Card.Footer className='p-0 border-top bg-light'>
                                                            <Row className='g-0'>
                                                                <Col xs={12} className=''>
                                                                    <Button className='bg-light text-red rounded-0 border-0 w-100 shadow-none fs-12' onClick={() => { setShowConfirmationDialog(true); selectedOrder = itm }}>
                                                                        Cancel Order
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Footer>

                                                        : itm.status === "FAILED" && itm.status === "CANCELLED" ?
                                                            // : itm.orderAgain === "Y" ?
                                                            <Card.Footer className='p-0 border-top bg-light'>
                                                                <Row className='g-0'>
                                                                    <Col xs={12} className=''>
                                                                        <Button className='bg-light-red rounded-0 border-0 w-100 shadow-none fs-12' onClick={() => navigate('/cart')}>
                                                                            <i class="fa-solid fa-rotate-left"></i> Order Again
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Footer>

                                                            : null}
                                                </Card>
                                            </Col>
                                        </>
                                    )
                                }
                                )}

                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '230px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '230px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '230px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '230px' }} />
                            </Placeholder >}
                        {/* <Col xs={12}>
                            <Card className='cardItem mb-2' >
                                <Card.Header className='p-2 border-bottom bg-light text-decoration-none text-dark' as='a' href='#' role='button'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className='d-flex align-items-start align-items-md-center'>
                                            <Image width='50px' height='50px' className='me-3 rounded' src={laziz} />
                                            <div className='w-100 align-self-center'>
                                                <h4 className="fs-14 fw-bold">Laziz Family Hall</h4>
                                                <div className='d-flex flex-wrap'>
                                                    <span className="fs-12 me-auto pe-3 text-blue  ">
                                                        Moga<span className='border-start ms-2 ps-2 '>Open at 10:30 AM</span>
                                                    </span>
                                                    <span className='fs-12 text-blue ms-auto'>View Menu </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body className='p-2 text-decoration-none' as='a' href='/ordersummary' role='button'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className=''>
                                            <ul className='list-group mb-2 fs-12'>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={VegIcon} /><span className='mx-1 text-blue fs-12'>2 *</span>Cheese Dosa </li>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={VegIcon} /><span className='mx-1 text-blue fs-12'>1 *</span>Masala Dosa</li>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={nonVegIcon} /><span className='mx-1 text-blue fs-12'>1 *</span>Paneer Tikka</li>
                                            </ul>
                                            <ul className='list-group list-group-flush' style={{ borderTop: '1px dashed #dee2e6' }}>
                                                <li className="list-group-item px-0">
                                                    <div className='d-flex flex-wrap' >
                                                        <span className="fs-12 me-auto pe-3 text-blue  ">
                                                            19 july 2023 at 12:47 PM
                                                        </span>
                                                        <span className='fs-12 fw-bold  ms-auto'><i class="fas fa-indian-rupee-sign fs-10"></i>610.00</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0 pb-0">
                                                    <div className='d-flex flex-wrap align-items-center'>
                                                        <span className="fs-12 me-auto pe-3 text-green">
                                                            <i class="fas fa-circle-check me-1"></i>Completed
                                                        </span>
                                                        <span className="fs-12 ms-auto text-blue">
                                                            More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className='p-0 border-top bg-light text-decoration-none text-dark'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className=''>
                                            <Button className='bg-light-red rounded-0 border-0 w-100 shadow-none fs-12' href='/cart'>
                                                <i class="fa-solid fa-rotate-left"></i> Order Again
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>

                        <Col xs={12}>
                            <Card className='cardItem mb-2' >
                                <Card.Header className='p-2 border-bottom bg-light text-decoration-none text-dark' as='a' href='#' role='button'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className='d-flex align-items-start align-items-md-center'>
                                            <Image width='50px' height='50px' className='me-3 rounded' src={grillmaster} />
                                            <div className='w-100 align-self-center'>
                                                <h4 className="fs-14 fw-bold">Grill Masters</h4>
                                                <div className='d-flex flex-wrap'>
                                                    <span className="fs-12 me-auto pe-3 text-blue  ">
                                                        Moga<span className='border-start ms-2 ps-2 '>24 mins</span>
                                                    </span>
                                                    <span className='fs-12 text-blue  ms-auto'>View Menu </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body className='p-2 text-decoration-none' as='a' href='/ordersummary' role='button'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className=''>
                                            <ul className='list-group mb-2 fs-12'>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={nonVegIcon} /><span className='mx-1 text-blue fs-12'>1 *</span>Tandoori Chicken Pizza</li>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={VegIcon} /><span className='mx-1 text-blue fs-12'>2 *</span>Cheese Dosa </li>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={VegIcon} /><span className='mx-1 text-blue fs-12'>1 *</span>Masala Dosa</li>
                                            </ul>
                                            <ul className='list-group list-group-flush' style={{ borderTop: '1px dashed #dee2e6' }}>
                                                <li className="list-group-item px-0">
                                                    <div className='d-flex flex-wrap' >
                                                        <span className="fs-12 me-auto pe-3 text-blue">
                                                            19 july 2023 at 12:47 PM
                                                        </span>
                                                        <span className='fs-12 fw-bold ms-auto'><i class="fas fa-indian-rupee-sign fs-10"></i>610.00</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0 pb-0">
                                                    <div className='d-flex flex-wrap align-items-center'>
                                                        <span className="fs-12 me-auto pe-3 text-red">
                                                            <i class="fas fa-circle-exclamation text-red me-1"></i>Payment failed
                                                        </span>
                                                        <span className="fs-12 ms-auto text-blue">
                                                            More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className='p-0 border-top bg-light text-decoration-none text-dark'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className=''>
                                            <Button className='bg-light-red rounded-0 border-0 w-100 shadow-none fs-12' href='/cart'>
                                                <i class="fa-solid fa-rotate-left"></i> Order Again
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <Card className='cardItem mb-2' >
                                <Card.Header className='p-2 border-bottom bg-light text-decoration-none text-dark' as='a' href='#' role='button'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className='d-flex align-items-start align-items-md-center'>
                                            <Image width='50px' height='50px' className='me-3 rounded' src={dominopizza} />
                                            <div className='w-100 align-self-center'>
                                                <h4 className="fs-14 fw-bold">Domino's Pizza</h4>
                                                <div className='d-flex flex-wrap'>
                                                    <span className="fs-12 me-auto pe-3 text-blue  ">
                                                        Moga<span className='border-start ms-2 ps-2 '>24 mins</span>
                                                    </span>
                                                    <span className='fs-12 text-blue ms-auto'>View Menu </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body className='p-2 text-decoration-none' as='a' href='/ordersummary' role='button'>
                                    <Row className='g-0 fs-14'>
                                        <Col xs={12} className=''>
                                            <ul className='list-group mb-2 fs-12'>
                                                <li className="list-group-item border-0 p-1 ps-0">
                                                    <Image width='14px' className='' src={VegIcon} /><span className='mx-1 text-blue fs-12'>1 *</span>Veggie Paradise Pizza</li>
                                            </ul>
                                            <ul className='list-group list-group-flush' style={{ borderTop: '1px dashed #dee2e6' }}>
                                                <li className="list-group-item px-0">
                                                    <div className='d-flex flex-wrap' >
                                                        <span className="fs-12 me-auto pe-3 text-blue  ">
                                                            19 july 2023 at 12:47 PM
                                                        </span>
                                                        <span className='fs-12 fw-bold  ms-auto'><i class="fas fa-indian-rupee-sign fs-10"></i>440.00</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0 pb-0">
                                                    <div className='d-flex flex-wrap align-items-center'>
                                                        <span className="fs-12 me-auto pe-3 text-red">
                                                            <i class="fa fa-times-circle text-red me-1"></i>Cancelled
                                                        </span>
                                                        <span className="fs-12 ms-auto text-blue">
                                                            More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> */}

                    </Row>
                </Col>
            </Row>

            {/* <Modal show={show} centered >
                    <Modal.Body>
                        <Row className='g-0 fs-14'>
                            <Col xs={12} className='text-end'>
                                <Button className='bg-transparent border-0 shadow-none text-dark' onClick={this.onCloseButton}>
                                    <i class="fas fa-close"></i>
                                </Button>
                            </Col>
                            <Col xs={12} className='mb-5'>
                                <div className='d-flex justify-content-center'>
                                    <input type='checkbox' className='check-input' id='check' />
                                    <label className='check-label' for="check">
                                        <div class="check-icon"></div>
                                    </label>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <h5 className='text-center fs-14 fw-bold'>
                                    Confirm Order
                                </h5>
                                <h5 className='text-center fs-12 mb-3'>
                                    Please Add Your Contact Number
                                </h5>
                            </Col>
                            <Col xs={12}>
                                <FloatingLabel className="mb-3" label="Contact Number" controlId="floatingTextarea" >
                                    <Form.Control className='shadow-box' type="text" placeholder="Contact Number" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12}>
                                <Button className='w-100 py-3 bg-light-blue text-truncate'>
                                    Done
                                </Button>
                            </Col>

                        </Row>
                    </Modal.Body>
                </Modal> */}
            <ConfirmationModal show={showConfirmationDialog} messageText="Are you sure you want to cancel the order?" hideModal={() => setShowConfirmationDialog(false)} handleClick={confirmCancelOrder} />
            <CancelOrderModal show={showMessage} onCloseButton={onCloseButton} />
        </>
    )
}


export default (OrderHistory);
