import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Image, ListGroup, Nav, Placeholder, Row, Badge, Stack, Toast, ToastContainer } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import DineModal from './DineModal';
import BrandModal from './BrandModal';
import MapModal from './MapModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getStoreProducts, fetchStoreDeliveryMethods, fetchOrderHistory } from '../../slice/dashBoardSlice';
import Cookies from 'js-cookie';
import SwitchBrandVerifierModal from '../SwitchBrandVerifier/SwitchBrandVerifierModal';
import noImage from '../../assets/images/no-image.png';
import noStoreImage from '../../assets/images/no_store_found.png';
import { checkAuthentication } from '../../utils';
import FilterModal from './FilterModal';

var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
};

function Home() {
    const baseUrl = localStorage.getItem('baseUrl');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showBrand, setShowBrand] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showBrandSwitchConfirmationModal, setShowBrandSwitchConfirmationModal] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [isGuest, setIsGuest] = useState(Cookies.get('guest'));
    const [store, setStore] = useState({});
    const [mainCategoryList, setMainCategoryList] = useState([]);
    const [mainCateIndex, setMainCateIndex] = useState(0);
    const [subCateIndex, setSubCateIndex] = useState(0);
    const [orderedItemsQty, setOrderedItemsQty] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [toastFade, setToastFade] = useState(false);
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [searchedItems, setSearchedItems] = useState([]);
    const [cartList, setCartList] = useState([]);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [expandedRow, setExpandedRow] = useState({ index: null, disable: false });

    const [prepareOrder, setPrepareOrder] = useState({ "orderDate": "", "orderTotal": "0", "totalGst": "0", "discount": "0", "discountType": "DEFAULT", "paymentStatus": "UNPAID", "deliveryStatus": "NONE", "amountPaid": "0", "status": "OPEN", "deliveryMethod": "", "paymentMethod": "COR", "customerId": "", "storeId": store.id ? store.id : "", "deliveryAddressId": "", "items": [] });

    const [searchBy, setSearchBy] = useState({ searchText: "", sortBy: "", filters: {} });

    useEffect(() => {
        async function callBack() {
            if (!checkAuthentication()) {
                navigate('/')
            } else {
                if (Cookies.get('location')) {
                    if (Cookies.get('store')) {
                        const store = JSON.parse(Cookies.get('store'));
                        if (store) {
                            setStore(store || {});
                            const selDeliveryMethod = await getDeliveryMethodsByStoreId(store.id);
                            getProductsByStoreId(store.id, selDeliveryMethod);
                            // setPrepareOrder({ ...prepareOrder, deliveryMethod: selDeliveryMethod })
                        }
                    } else {
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            }
        }
        callBack()
    }, [Cookies]);

    const getProductsByStoreId = async (storeId, currentDeliveryMethod) => {
        try {
            await dispatch(getStoreProducts(storeId)).then(async (response) => {
                if (response.payload) {
                    const allProducts = [];
                    const selectedCategory = localStorage.getItem('selectedCategory') ? JSON.parse(localStorage.getItem('selectedCategory')) : null;
                    response.payload.forEach(cat => {
                        if (cat.categories) {
                            cat.categories.forEach(subCat => {
                                if (subCat.products) {
                                    subCat.products.forEach(prod => {
                                        if (prod) {
                                            allProducts.push(prod);
                                        }
                                    })
                                }
                            })
                        }
                    })
                    if (localStorage.getItem('cart')) {
                        let cart = JSON.parse(localStorage.getItem('cart'));

                        setPrepareOrder({ ...cart, deliveryMethod: currentDeliveryMethod });
                        let mainCategoryListClone = [...response.payload];

                        if (mainCategoryListClone && mainCategoryListClone.length > 0) {
                            mainCategoryListClone.map((mCate) => {
                                if (mCate.categories && mCate.categories.length > 0) {
                                    mCate.categories.map((sCate) => {
                                        if (sCate && sCate.products && sCate.products.length > 0) {
                                            sCate.products.map((prd) => {
                                                let findItem = cart.items.find(itm => itm.productId === prd.id);
                                                if (findItem !== undefined) {
                                                    prd.qty = findItem.qty;
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        };

                        await dispatch(fetchOrderHistory()).then(async (response) => {
                            let carts = [];
                            if (response.payload && response.payload.length > 0) {
                                await response.payload.map((order) => {
                                    if (order.status === "OPEN") {
                                        carts.push(order);
                                    }
                                    return order
                                })
                                if (cart && cart.items && cart.items.length > 0) {
                                    carts.push(cart);
                                }
                                setCartList(carts);
                            } else {
                                setCartList([]);
                            }
                        });

                        mainCategoryListClone.unshift({ id: "all", catAlias: "All", categories: [{ 'id': 'main', 'catAlias': 'Main', 'products': allProducts }] });

                        if (selectedCategory) {
                            if (selectedCategory.id !== 'all') {
                                const selIndex = mainCategoryListClone.findIndex(e => e.id === selectedCategory.id)
                                if (selIndex > -1) {
                                    setMainCateIndex(selIndex);
                                }
                            } else {
                                setMainCateIndex(0);
                            }
                        } else {
                            setMainCateIndex(0);
                        }
                        handlePaginator([...mainCategoryListClone])
                        setTimeout(() => {
                            setDataLoaded(true);
                        }, 500);


                    } else {
                        let mainCategoryListClone = [...response.payload];
                        mainCategoryListClone.unshift({ id: "all", catAlias: "All", categories: [{ 'id': 'main', 'catAlias': 'Main', 'products': allProducts }] });
                        if (selectedCategory) {
                            if (selectedCategory.id !== 'All') {
                                const selIndex = mainCategoryListClone.findIndex(e => e.id === selectedCategory.id)
                                if (selIndex > -1) {
                                    setMainCateIndex(selIndex);
                                }
                            } else {
                                setMainCateIndex(0);
                            }
                        } else {
                            setMainCateIndex(0);
                        }
                        setPrepareOrder({ ...prepareOrder, deliveryMethod: currentDeliveryMethod })
                        handlePaginator([...mainCategoryListClone])
                        await dispatch(fetchOrderHistory()).then((response) => {
                            let carts = [];
                            if (response.payload && response.payload.length > 0) {
                                response.payload.map((order) => {
                                    if (order.status === "OPEN") {
                                        carts.push(order);
                                    }
                                })
                                setCartList(carts);
                            } else {
                                setCartList([]);
                            }
                        });


                        setTimeout(() => {
                            setDataLoaded(true);
                        }, 500);
                    }
                }
            })
        } catch (err) {
            console.log(err);
        }
    };

    const handlePaginator = (catList = []) => {
        const chunkRecords = [...catList];
        for (let i = 0; i < 4; i++) {
            if (chunkRecords.length < 4) {
                chunkRecords.push({});
            }
        }
        setMainCategoryList(chunkRecords)
    }

    const getDeliveryMethodsByStoreId = async (storeId) => {
        let deliveryMethod = ''
        try {
            await dispatch(fetchStoreDeliveryMethods(storeId)).then((response) => {
                if (response.payload && Array.isArray(response.payload)) {
                    setDeliveryMethods(response.payload);
                    deliveryMethod = response.payload.length > 0 ? response.payload[0].methodCode : '';
                }
            })
        } catch (err) {
            console.log(err);
        }
        return deliveryMethod;
    };

    const onCloseButton = (store) => {
        setShowBrand(false);
        let cart = JSON.parse(localStorage.getItem('cart'));
        if (cart && cart.items && cart.items.length > 0) {
            setShowBrandSwitchConfirmationModal(true);
        } else {
            setDataLoaded(false);
            if (store && store.id) {
                Cookies.set('store', JSON.stringify(store));
                setStore(store || {});
                getProductsByStoreId(store.id, prepareOrder.deliveryMethod);
            }
        }
    }

    const onCloseBrandModal = () => {
        setShowBrand(false);
    }

    const onOpenModalBtn = () => {
        setShowModal(true);
    }

    const onCloseModalBtn = () => {
        setShowModal(false);
    }

    const onCloseMapButton = () => {
        setShowMap(false);
    }

    const handleSearchToggle = (event) => {
        const { value } = event.target;

        if (value) {
            setSearchBy({ ...searchBy, searchText: value });
            let prdList = [];
            const foundedCat = mainCategoryList.find(e => e.id === 'all');
            if (foundedCat && foundedCat.categories) {
                foundedCat.categories[0].products.map(prod => {
                    if (prod.prodAlias && prod.prodAlias.toLowerCase().includes(value.toLowerCase())) {
                        prdList.push(prod);
                    }
                })
            }
            setExpandedRow({ ...expandedRow, disable: false, index: null })
            if (prdList.length > 0) {
                setSearchedItems(prdList);
            } else {
                setSearchedItems([]);
            }

        } else {
            setSearchedItems([]);
            setSearchBy({ ...searchBy, searchText: "" });
        }
    };

    const handleSearchCancel = () => {
        setIsSearchActive(false);
    };

    const clearUserdata = () => {
        Cookies.remove('authenticated');
        Cookies.remove('guest');
        Cookies.remove('store');
        Cookies.remove('E_COMM_TOKEN');
        localStorage.removeItem('cart');
        localStorage.removeItem('baseUrl');
        Cookies.remove('location');
        window.location.reload();
    }

    const handleArrowBtn = async (index) => {
        const currIndex = expandedRow.index === index ? null : index;
        setExpandedRow({ ...expandedRow, index: currIndex, disable: true });
        setTimeout(() => {
            setExpandedRow({ ...expandedRow, index: currIndex, disable: false });
        }, 500);
    };

    const changeMainCategory = (idx) => {
        if (idx > -1) {
            localStorage.setItem('selectedCategory', JSON.stringify(mainCategoryList[idx]));
        }
        setExpandedRow({ ...expandedRow, index: null })
        setMainCateIndex(idx);
        handleSearchCancel();
        setSearchBy({ searchText: "", sortBy: "", filters: {} });
        setSearchedItems([]);
        setSubCateIndex(0);
    };

    const changeSubCategory = (idx) => {
        handleSearchCancel();
        setSearchBy({ searchText: "", sortBy: "", filters: {} });
        setSearchedItems([]);
        setSubCateIndex(idx);
    };

    useEffect(() => {
        refreshCart();
    }, [prepareOrder]);

    const refreshCart = () => {
        let orderTotal = 0;
        let totalGst = 0;
        let subTotal = 0;
        let totalItemQty = 0;
        if (prepareOrder && prepareOrder.items.length > 0) {
            Promise.all(
                prepareOrder.items.map((item) => {
                    if (item.isPriceGstInc === 'Y') {
                        const itemGst = item.perGst ? parseFloat((item.price * item.perGst / (100)).toFixed(2)) : 0;
                        subTotal = subTotal + parseFloat((item.price - itemGst).toFixed(2) * item.qty);
                        totalGst = totalGst + parseFloat((itemGst * item.qty).toFixed(2));
                    } else {
                        const itemGst = item.perGst ? parseFloat((item.price * item.perGst / 100).toFixed(2)) : 0;
                        subTotal = subTotal + parseFloat((item.price * item.qty).toFixed(2));
                        totalGst = totalGst + parseFloat((itemGst * item.qty).toFixed(2));
                    }
                    totalItemQty = totalItemQty + 1
                })).then(() => {
                    orderTotal = ((parseFloat(subTotal) + parseFloat(totalGst)) + parseFloat(orderTotal)).toFixed(2);
                    if (parseFloat(orderTotal) !== parseFloat(prepareOrder.orderTotal)) {
                        setPrepareOrder({ ...prepareOrder, orderTotal: orderTotal, totalGst: totalGst, subTotal: subTotal })
                        localStorage.setItem('cart', JSON.stringify({ ...prepareOrder, orderTotal: orderTotal, subTotal: subTotal, totalGst: totalGst }));
                    }
                    setOrderedItemsQty(totalItemQty);
                })
        }
    }

    const addProductToCart = (item) => {
        let mainCategoryListClone = JSON.parse(JSON.stringify(mainCategoryList));
        if (mainCategoryListClone) {
            mainCategoryListClone.map((mCate) => {
                if (mCate && mCate.categories) {
                    mCate.categories.map((sCate) => {
                        if (sCate && sCate.products) {
                            sCate.products.map((prd) => {
                                if (prd.id === item.id) {
                                    const product = {
                                        "itemNumber": prd.itemNumber,
                                        "hsn": prd.hsn,
                                        "uom": prd.uom,
                                        "perGst": prd.perGst,
                                        "price": prd.price,
                                        "icon": prd.icon,
                                        "qty": 1,
                                        "discount": "0",
                                        "productId": prd.id,
                                        "instructions": "",
                                        "name": prd.prodAlias,
                                        "isPriceGstInc": prd.isPriceGstInc
                                    }
                                    prd.qty = 1;
                                    let items = JSON.parse(JSON.stringify(prepareOrder.items));
                                    items.push(product);
                                    localStorage.setItem('cart', JSON.stringify({ ...prepareOrder, items }));
                                    setPrepareOrder({ ...prepareOrder, items })
                                }
                            })
                        }
                    })
                }
            })
        }

        let searchedItemsClone = JSON.parse(JSON.stringify(searchedItems));

        if (searchedItemsClone) {
            searchedItemsClone.map((sPrd) => {
                if (sPrd.id === item.id) {
                    sPrd.qty = 1;
                }
            })
        }

        let cart = JSON.parse(localStorage.getItem('cart'));
        let cartListClone = JSON.parse(JSON.stringify(cartList));
        if (cart && cart.items && cart.items.length > 0) {
            let isCartExist = cartListClone.findIndex((cart) => cart.id === undefined || cart.id === "");
            if (isCartExist == -1) {
                cartListClone.push(cart);
                setCartList(cartListClone);
            }
        } else {
            let filterCarts = cartListClone.filter((cart) => cart.id);
            setCartList(filterCarts);
        }

        setSearchedItems([...searchedItemsClone]);
        setMainCategoryList([...mainCategoryListClone]);
    }

    const inc = (item) => {
        let mainCategoryListClone = JSON.parse(JSON.stringify(mainCategoryList));

        if (mainCategoryListClone) {
            mainCategoryListClone.map((mCate) => {
                if (mCate && mCate.categories) {
                    mCate.categories.map((sCate) => {
                        if (sCate && sCate.products) {
                            sCate.products.map((prd) => {
                                if (prd.id === item.id) {
                                    prd.qty = prd.qty + 1;
                                    if (prd.id === item.id) {
                                        let items = JSON.parse(JSON.stringify(prepareOrder.items));
                                        let updateItem = items.find(itm => itm.productId === prd.id);
                                        if (updateItem) {
                                            let updateItemIndex = items.findIndex(itm => itm.productId === prd.id);
                                            updateItem.qty = prd.qty;
                                            items[updateItemIndex] = updateItem;
                                            setPrepareOrder({ ...prepareOrder, items })
                                        }
                                    }
                                }
                            })
                        }
                    })
                }
            })
        }

        let searchedItemsClone = JSON.parse(JSON.stringify(searchedItems));

        if (searchedItemsClone) {
            searchedItemsClone.map((sPrd) => {
                if (sPrd.id === item.id) {
                    sPrd.qty = sPrd.qty + 1;
                }
            })
        }

        let cart = JSON.parse(localStorage.getItem('cart'));
        let cartListClone = JSON.parse(JSON.stringify(cartList));
        if (cart && cart.items && cart.items.length > 0) {
            let isCartExist = cartListClone.findIndex((cart) => cart.id === undefined || cart.id === "");
            if (isCartExist === -1) {
                cartListClone.push(cart);
                setCartList(cartListClone);
            }
        } else {
            let filterCarts = cartListClone.filter((cart) => cart.id);
            setCartList(filterCarts);
        }

        setSearchedItems(searchedItemsClone);
        setMainCategoryList(mainCategoryListClone);
    };

    const dec = (item) => {
        let mainCategoryListClone = JSON.parse(JSON.stringify(mainCategoryList));

        if (mainCategoryListClone) {
            mainCategoryListClone.map((mCate) => {
                if (mCate && mCate.categories) {
                    mCate.categories.map((sCate) => {
                        if (sCate && sCate.products) {
                            sCate.products.map((prd) => {
                                if (prd.id === item.id) {
                                    if (prd.qty && prd.qty !== 0) {
                                        prd.qty = prd.qty - 1;
                                        let items = JSON.parse(JSON.stringify(prepareOrder.items));
                                        let updateItem = items.find(itm => itm.productId === prd.id);
                                        if (updateItem) {
                                            let updateItemIndex = items.findIndex(itm => itm.productId === prd.id);
                                            updateItem.qty = prd.qty;
                                            items[updateItemIndex] = updateItem;
                                            if (prd.qty === 0) {
                                                items.splice(updateItemIndex, 1);
                                            }
                                            localStorage.setItem('cart', JSON.stringify({ ...prepareOrder, items }))
                                            setPrepareOrder({ ...prepareOrder, items })
                                        }
                                    }
                                }
                            })
                        }
                    })
                }

            })
        }

        let searchedItemsClone = JSON.parse(JSON.stringify(searchedItems));

        if (searchedItemsClone) {
            searchedItemsClone.map((sPrd) => {
                if (sPrd.id === item.id) {
                    if (sPrd.qty && sPrd.qty !== 0) {
                        sPrd.qty = sPrd.qty - 1;
                    }
                }
            })
        }


        let cart = JSON.parse(localStorage.getItem('cart'));
        let cartListClone = JSON.parse(JSON.stringify(cartList));
        if (cart && cart.items && cart.items.length > 0) {
            let isCartExist = cartListClone.findIndex((cart) => cart.id === undefined || cart.id === "");
            if (isCartExist == -1) {
                cartListClone.push(cart);
                setCartList(cartListClone);
            }
        } else {
            let filterCarts = cartListClone.filter((cart) => cart.id);
            setCartList(filterCarts);
        }

        setSearchedItems(searchedItemsClone);
        setMainCategoryList(mainCategoryListClone);
    };

    const switchOrderType = (orderType) => {
        localStorage.setItem('cart', JSON.stringify({ ...prepareOrder, deliveryMethod: orderType }));
        setPrepareOrder({ ...prepareOrder, deliveryMethod: orderType })
        setShowModal(false);
    }

    const getProductDetails = (item) => {
        localStorage.setItem('prdId', item.id);
        navigate('/productdetails');
    }

    const onOpenToastButton = () => {
        setToastFade(!toastFade);
    };

    const confirmOrderSwitch = (value) => {
        setShowBrandSwitchConfirmationModal(false);
        if (value === 'YES') {
            setDataLoaded(false);
            if (store && store.id) {
                localStorage.removeItem('cart');

                setOrderedItemsQty(0);
                setPrepareOrder({
                    "orderDate": "",
                    "orderTotal": "0",
                    "totalGst": "0",
                    "discount": "0",
                    "discountType": "DEFAULT",
                    "paymentStatus": "UNPAID",
                    "deliveryStatus": "NONE",
                    "amountPaid": "0",
                    "status": "OPEN",
                    "deliveryMethod": prepareOrder.deliveryMethod,
                    "paymentMethod": "CASH",
                    "customerId": "",
                    "storeId": store.id ? store.id : "",
                    "deliveryAddressId": "",
                    "items": []
                })
                Cookies.set('store', JSON.stringify(store));
                setStore(store || {});
                getProductsByStoreId(store.id, prepareOrder.deliveryMethod);
            }
        }
    }

    const moveToOrderSummaryPage = (order) => {
        navigate('/ordersummary?orderId=' + order.id);
    };

    const onFilterSubmit = (newSearchFilters) => {
        const products = mainCategoryList && mainCategoryList.length > 0 && mainCategoryList[mainCateIndex] && mainCategoryList[mainCateIndex].categories && mainCategoryList[mainCateIndex].categories[subCateIndex] && mainCategoryList[mainCateIndex].categories[subCateIndex].products && mainCategoryList[mainCateIndex].categories[subCateIndex].products;
        let emptyList = [];
        if (products) {
            if (newSearchFilters.filters) {
                emptyList = products.filter((prod) => (!newSearchFilters?.filters?.minPrice || parseFloat(prod.price || 0) >= parseFloat(newSearchFilters?.filters?.minPrice || 0)) && (!newSearchFilters?.filters.maxPrice || parseFloat(prod.price || 0) <= parseFloat(newSearchFilters?.filters.maxPrice || 0)));
            }
            if (newSearchFilters.sortBy !== '') {
                if (newSearchFilters.sortBy === 'asc') {
                    emptyList = emptyList.sort((a, b) => { return a.price - b.price });
                } else {
                    emptyList = emptyList.sort((a, b) => { return b.price - a.price });
                }
            }
            setSearchBy(newSearchFilters);
            setShowFilterModal(false);
            setSearchedItems([...emptyList]);
        }
    };

    return (
        <>
            <Row id='full-screen' className='g-0 justify-content-center position-relative bg-pages'>
                <span className=' bg-img'></span>
                <Col xs={12} md={12} lg={6} style={{ zIndex: '1' }} className='position-relative'>
                    <Row className='g-0 p-3 d-flex justify-content-center'>
                        <Col xs={12} md={12} className='d-flex align-items-center' style={{ gap: '10px' }}>
                            <div className="searchBox">
                                {dataLoaded === true ?
                                    <>
                                        <Button size='sm' className='search-icon' >
                                            <i class="fas fa-search"></i>
                                        </Button>
                                        <Form.Control className='form-input' placeholder="Search item by name" value={searchBy.searchText} aria-label="search" aria-describedby="basic-addon1" onChange={handleSearchToggle} />
                                        {/* <Button size='sm' className='voice-icon-button'>
                                            <i class="fas fa-microphone ms-2"></i>
                                        </Button> */}
                                    </> :
                                    <Placeholder animation="glow" >
                                        <Placeholder xs={12} className='rounded' style={{ height: '40px' }} />
                                    </Placeholder>}
                            </div>
                            <div className={isSearchActive ? "search-block is-active" : "search-block"}>
                                <form className="search-form">
                                    <Button variant='primary' size='sm' className='search-cancel ms-auto me-md-2' type='button' onClick={handleSearchCancel}>
                                        <i class="fa fa-arrow-left fs-6 swingIcon"></i>
                                    </Button>
                                    <Form.Control type='search' className='search-input' placeholder="Search for atta dal and more" aria-label="search" aria-describedby="basic-addon1" />
                                </form>
                            </div>
                            <div>
                                {dataLoaded === true ?
                                    <Button variant='primary' className='light-btn bg-white shadow-none text-blue h-100' style={{ fontSize: '12px', minHeight: '40px' }} onClick={(() => setShowFilterModal(true))}>
                                        <i class="fa-solid fa-filter"></i>
                                    </Button> :
                                    <Placeholder animation="glow"  >
                                        <Placeholder xs={12} className='rounded' style={{ width: '60px', height: '40px' }} />
                                    </Placeholder>
                                }
                            </div>
                            {dataLoaded === true ?
                                <Button variant='primary' className='light-btn bg-white shadow-none text-blue h-100' style={{ fontSize: '12px', minHeight: '40px' }} onClick={onOpenModalBtn}>
                                    {prepareOrder.deliveryMethod}
                                </Button> :
                                <Placeholder animation="glow"  >
                                    <Placeholder xs={12} className='rounded' style={{ width: '60px', height: '40px' }} />
                                </Placeholder>
                            }
                            {/* {dataLoaded === true ? */}
                            <>
                                {/* <Button variant='primary' className='light-btn bg-white shadow-none h-100' onClick={() => navigate('/otps')}>
                                        <i class="fas fa-phone text-blue "></i>
                                    </Button> */}
                                {/* <Button variant='primary' className='light-btn bg-white shadow-none h-100' onClick={onOpenMapButton}>
                                        <i class="fas fa-location-dot text-blue "></i>
                                    </Button> */}
                            </>
                            {/* : */}
                            {/* <Placeholder animation="glow"  >
                                    <Placeholder xs={12} className='rounded' style={{ width: '40px', height: '40px' }} />
                                </Placeholder> */}
                            {/* } */}
                            {dataLoaded === true ?
                                <>
                                    {isGuest === "true" ?
                                        < Dropdown className='h-100'>
                                            <Dropdown.Toggle className='light-btn bg-white shadow-none h-100 actionBtn' id="dropdown-basic" >
                                                <i class="fas fa-user text-blue"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end" className='border-0'>
                                                {Cookies.get('guest') ?
                                                    <Dropdown.Item onClick={() => navigate('/enternumber')}>Log In</Dropdown.Item> :
                                                    <Dropdown.Item onClick={() => clearUserdata()}>Logout</Dropdown.Item>}
                                            </Dropdown.Menu>

                                        </Dropdown> :
                                        <Dropdown className='h-100'>
                                            <Dropdown.Toggle className='light-btn bg-white shadow-none h-100 actionBtn' id="dropdown-basic">
                                                <i class="fas fa-user text-blue"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu align="end" className='border-0'>
                                                <Dropdown.Item href="/yourprofile">Profile</Dropdown.Item>
                                                <Dropdown.Item href="/orderhistory">History</Dropdown.Item>
                                                <Dropdown.Item href="#/action-5">Settings</Dropdown.Item>
                                                <Dropdown.Item onClick={() => clearUserdata()}>Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </>
                                :
                                <Placeholder animation="glow"  >
                                    <Placeholder xs={12} className='rounded' style={{ width: '40px', height: '40px' }} />
                                </Placeholder>
                            }
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xs={12}>
                            <Card className='product-card'>
                                <Card.Header className='py-2' >
                                    <Row className='g-0'>
                                        {dataLoaded === true ?
                                            <Col xs={5} sm={5} className='d-flex align-items-center'>
                                                <div className=''>
                                                    <Button variant='primary' className='light-btn brand_img position-relative p-0 text-nowrap d-flex align-items-start' onClick={() => navigate('/')}>
                                                        <Image className='' src={store.storeLogo ? baseUrl + store.storeLogo : noStoreImage} />
                                                    </Button>
                                                </div>
                                                <div className='ms-3 w-100 text-truncate'>
                                                    <span className='d-flex flex-wrap align-items-center'>
                                                        <div className='d-flex align-items-center me-auto pe-2'>
                                                            <Card.Title className='fs-14 fw-bold mb-0 DroidSerif' >{store && store.name ? store.name : ""}</Card.Title>
                                                            {/* <div className='divider'></div> */}
                                                            {/* <span className='fs-12'>{store && store.location ? store.location : ""}</span> */}
                                                        </div>
                                                        {/* <div className="d-flex align-items-center justify-content-end ms-auto">
                                                            <div className="d-flex align-items-center">
                                                                <Button className='bg-transparent text-blue border-0  p-0' onClick={() => navigate('/brandinformation')} >
                                                                    <i class="fas fa-circle-info fs-14"></i>
                                                                </Button>
                                                            </div>
                                                            <div className='divider'></div>
                                                            <div className="d-flex align-items-center">
                                                                <span className="fs-12 text-blue">4.4</span>
                                                                <span className="small-ratings ms-1">
                                                                    <i class="fas fa-star rating-color"></i>
                                                                </span>
                                                            </div>
                                                            <div className='divider'></div>
                                                            <Button className='bg-transparent text-blue border-0 p-0' onClick={onOpenButton} >
                                                                <i class="fas fa-retweet fs-14"></i>
                                                            </Button>
                                                        </div> */}
                                                    </span>
                                                    <span className=''>
                                                        <Card.Title className='fs-12 mb-0 text-truncate pe-5 me-md-5 me-lg-0 text-blue'>
                                                            <i class="fas fa-location-dot me-1"></i><span className='fw-bold me-1'>{store.address ? store.address + "," : ""}</span>
                                                            {store && store.location ? store.location : ""}
                                                        </Card.Title>
                                                    </span>
                                                </div>
                                            </Col> :
                                            <Placeholder animation="glow" className="d-flex align-items-center" >
                                                <Placeholder xs={3} className='rounded-circle' style={{ width: '50px', height: '50px' }} />
                                                <div className='w-100 text-truncate'>
                                                    <span className='d-flex flex-wrap align-items-center'>
                                                        <Placeholder xs={3} className='ms-3 me-auto' />
                                                        {/* <Placeholder xs={3} className=' ms-auto' /> */}
                                                    </span>
                                                    <Placeholder xs={6} className='ms-3 me-auto' />
                                                </div>
                                            </Placeholder>
                                        }
                                        {/* <Col xs={7} sm={7} className='d-flex align-items-center justify-content-end'>
                                            <Stack direction="horizontal" gap={2}>
                                                <Badge pill bg="secondary" className='filter-badge'>
                                                    Low to high
                                                    <span className='position-absolute filter-remove'>
                                                        <i class="fa-solid fa-xmark"></i>
                                                    </span>
                                                </Badge>
                                                <Badge pill bg="secondary" className='filter-badge'>
                                                    Min price 10000
                                                    <span className='position-absolute filter-remove'>
                                                        <i class="fa-solid fa-xmark"></i>
                                                    </span>
                                                </Badge>
                                                <Badge pill bg="secondary" className='filter-badge'>
                                                    Max price 40000
                                                    <span className='position-absolute filter-remove'>
                                                        <i class="fa-solid fa-xmark"></i>
                                                    </span>
                                                </Badge>
                                            </Stack>
                                        </Col> */}
                                    </Row>
                                </Card.Header>
                                <Card.Body className='p-0'>
                                    <Row className='g-0'>
                                        <Col xs={12} className='p-3 bg-white shadow_sm'>
                                            {dataLoaded === true ?
                                                <Nav className="">
                                                    <Slider className='item-nav-tabs' {...settings}>
                                                        {mainCategoryList && mainCategoryList.length > 0 ?
                                                            mainCategoryList.map((item, idx) => {
                                                                return (
                                                                    <Nav.Item onClick={() => changeMainCategory(idx)} key={idx}>
                                                                        <Nav.Link eventKey={idx} active={idx === mainCateIndex ? true : false}>
                                                                            <span className='d-flex align-items-center justify-content-center fs-12'>
                                                                                {/* <Image width={15} className='me-2' src={baseUrl + item.icon} /> */}
                                                                                {item?.catAlias}
                                                                            </span>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            })
                                                            : null}
                                                    </Slider>
                                                </Nav> :
                                                <Placeholder animation="glow" as='div' style={{ display: 'flex', gap: '10px' }} >
                                                    <Placeholder className='rounded' style={{ width: '100%', height: '34px' }} />
                                                    <Placeholder className='rounded' style={{ width: '100%', height: '34px' }} />
                                                    <Placeholder className='rounded' style={{ width: '100%', height: '34px' }} />
                                                    <Placeholder className='rounded' style={{ width: '100%', height: '34px' }} />
                                                </Placeholder>
                                            }
                                        </Col>
                                        <Col xs={12} className='product-card-height p-0 mt-2'>
                                            <Row className="g-0">
                                                <Col xs={12}>

                                                    <Row className='g-2 mx-0'>
                                                        <Col xs={2} sm={3} md={3} lg={4} className='category_list_left left_width ps-2'>
                                                            {dataLoaded === true ?
                                                                <ListGroup className='mb-2'>
                                                                    <div className='list-group-scroll'>
                                                                        {mainCategoryList && mainCategoryList.length > 0 && mainCategoryList[mainCateIndex] && mainCategoryList[mainCateIndex].categories.map((subCate, idx) => {
                                                                            if (subCate) {
                                                                                return (
                                                                                    <>
                                                                                        <ListGroup.Item key={idx} action href="#6" className='p-2' onClick={() => changeSubCategory(idx)} active={idx === subCateIndex ? true : false} style={{ background: idx === subCateIndex ? '#00000012' : '' }}>
                                                                                            <span className='right_border'>abc</span>
                                                                                            <span className='d-md-flex flex-wrap flex-md-nowrap d-block align-items-center'>
                                                                                                <div className=' pe-0 pe-md-3 mb-1 mb-md-0 text-center'>
                                                                                                    {/* <Image width={35} className='rounded' src={subCate.icon ? baseUrl + subCate.icon : noImage} /> */}
                                                                                                </div>
                                                                                                <span className='fs-12 fs_xs_10 d-block text-center me-md-auto me-0'>
                                                                                                    {subCate?.catAlias}
                                                                                                </span>
                                                                                            </span>
                                                                                        </ListGroup.Item >
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </ListGroup> :
                                                                <Placeholder animation="glow" as='row' className='g-2'>
                                                                    <Placeholder xs={12} className='rounded mb-2 list-group-scroll' />
                                                                </Placeholder>}
                                                        </Col>
                                                        <Col xs={10} sm={9} md={9} lg={8} className='category_list_right pe-2'>
                                                            {dataLoaded === true ?
                                                                <>
                                                                    {searchBy.searchText !== '' || searchBy.sortBy !== '' || Object.keys(searchBy.filters).length > 0 ?
                                                                        <>
                                                                            <Row className="g-0">
                                                                                <Col xs={12}>
                                                                                    <Row className="g-2">
                                                                                        {searchedItems && searchedItems.length > 0 && searchedItems.map((prod, index) => {
                                                                                            return (
                                                                                                <Col xs={6} key={index}>
                                                                                                    <Card className='cardItem position-relative w-100 h-100' >
                                                                                                        <Card.Body className='p-2 text-decoration-none d-flex flex-column justify-content-between'>
                                                                                                            <div className='position-relative mb-2'>
                                                                                                                <Image width='100%' height='100%' className={`object-fit-cover rounded`} src={prod.icon ? (baseUrl + prod.icon) : noImage} onClick={() => getProductDetails(prod)} />
                                                                                                                <div className='position-absolute bottom-0 end-0 mb-1 me-1'>
                                                                                                                    {prod.qty && prod.qty > 0 === true ?
                                                                                                                        <>
                                                                                                                            <Card className={`card-item addQty_card ${prod.qty && prod.qty > 0 ? '' : 'bg-light-grey'}`}>
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(prod)}>
                                                                                                                                    <i class="fas fa-minus"></i>
                                                                                                                                </Button>
                                                                                                                                <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={prod.qty} />
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(prod)}>
                                                                                                                                    <i class="fas fa-plus"></i>
                                                                                                                                </Button>
                                                                                                                            </Card>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <Button style={{ padding: '.42rem .7rem' }} className=' light-btn bg-white  text-blue fs-12 rounded border-secondary-subtle' onClick={() => addProductToCart(prod)}>
                                                                                                                            <i class="fa-solid fa-cart-shopping"></i>
                                                                                                                        </Button>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=''>
                                                                                                                <div className=''>
                                                                                                                    <h5 className='fs-12 mb-0 text-truncate'>{prod.prodAlias}</h5>
                                                                                                                    <span className='fs-14 mb-0 fw-bold'>
                                                                                                                        <i class="fas fa-indian-rupee-sign text-blue"></i>{prod.price}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                {/* <div className='text-end'>
                                                                                                                    <Button variant='primary' className=' border-0 bg-transparent text-blue fs-12  p-0' onClick={() => getProductDetails(prod)}>
                                                                                                                        More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                                                                                    </Button>
                                                                                                                </div> */}
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            )
                                                                                        })}
                                                                                    </Row>
                                                                                </Col>
                                                                                {/* <Col xs={12} >
                                                                                    <div class="accordion" id="accordionExample">
                                                                                        {searchedItems && searchedItems.length > 0 && searchedItems.map((prod, index) => {
                                                                                            return (
                                                                                                <div className="accordion-item new_accordion" key={index}>
                                                                                                    <h2 className="accordion-header" id={"headingOne" + index}>
                                                                                                        <Image className={`object-fit-cover rounded me-3 accor_img ${expandedRow.index !== index ? '' : 'accor_img_lg'}`} src={prod.icon ? (baseUrl + prod.icon) : noImage} />
                                                                                                        {expandedRow.index !== index ?
                                                                                                            <>
                                                                                                                <span className='accor_text_one text-truncate'>{prod.prodAlias}</span>
                                                                                                                <span className='accor_price text-start text-truncate'>
                                                                                                                    {prod.qty && prod.qty > 0 === true ?
                                                                                                                        <>
                                                                                                                            <Card className={`card-item addQty_card mx-auto ${prod.qty && prod.qty > 0 ? '' : 'bg-light-grey'}`}>
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(prod)}>
                                                                                                                                    <i class="fas fa-minus"></i>
                                                                                                                                </Button>
                                                                                                                                <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={prod.qty} />
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(prod)}>
                                                                                                                                    <i class="fas fa-plus"></i>
                                                                                                                                </Button>
                                                                                                                            </Card>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <Button style={{ padding: '.42rem .5rem' }} className='border bg-transparent text-dark fs-12 rounded d-block mx-auto addQty_card border-secondary-subtle' onClick={() => addProductToCart(prod)}>
                                                                                                                            Add <i class="fas fa-indian-rupee-sign fs-10 text-blue  ms-1"></i>{prod.price}
                                                                                                                        </Button>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <Row className='g-0 w-100'>
                                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                                    <span className='fs-14 mb-0 text-truncate accor_text_two'>
                                                                                                                        {prod.prodAlias}
                                                                                                                    </span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        }
                                                                                                        <button className={`accordion-button arrow_btn ${expandedRow.index !== index ? "collapsed" : ''}`} type="button" disabled={expandedRow.disable} onClick={() => handleArrowBtn(index)} data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded={expandedRow.index === index ? "true" : "false"} aria-controls={"collapseOne" + index}>
                                                                                                        </button>
                                                                                                    </h2>
                                                                                                    <div id={"collapseOne" + index} className={`accordion-collapse collapse ${expandedRow.index === index ? "show" : ""}`} aria-labelledby={"headingOne" + index} data-bs-parent="#accordionExample">
                                                                                                        <div className="accordion-body">
                                                                                                            <Row className='g-2'>
                                                                                                                <Col xs={12} sm={12} className='position-relative'>
                                                                                                                    <span className='fs-14 d-flex flex-wrap align-items-end'>
                                                                                                                        <span className='me-auto pe-3 d-flex align-items-center'>

                                                                                                                            <Card className={`card-item addQty_card ${prod.qty && prod.qty > 0 === true ? '' : 'bg-light-grey'}`}>
                                                                                                                                {prod.qty && prod.qty > 0 === true ?
                                                                                                                                    <>
                                                                                                                                        <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(prod)}>
                                                                                                                                            <i class="fas fa-minus"></i>
                                                                                                                                        </Button>
                                                                                                                                        <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={prod.qty} />
                                                                                                                                        <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(prod)}>
                                                                                                                                            <i class="fas fa-plus"></i>
                                                                                                                                        </Button>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <Button className='border-0 bg-transparent text-dark fs-12 w-100' onClick={() => addProductToCart(prod)}>
                                                                                                                                        <i class="fas fa-plus fs-12"></i> Add
                                                                                                                                    </Button>
                                                                                                                                }
                                                                                                                            </Card>
                                                                                                                            <span className="fs-14 mb-0 fw-bold ms-3">
                                                                                                                                <i class="fas fa-indian-rupee-sign fs-12 text-blue"></i>{prod.price}
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                        <span className='ms-2 ms-auto'>
                                                                                                                            <Button variant='primary' className=' border-0 bg-transparent text-blue fs-12  p-0' onClick={() => getProductDetails(prod)}>
                                                                                                                                More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                                                                                            </Button>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </Col> */}
                                                                            </Row>
                                                                        </> :
                                                                        <Row className="g-0">
                                                                            <Col xs={12}>
                                                                                <Row className="g-2">
                                                                                    {mainCategoryList && mainCategoryList.length > 0 && mainCategoryList[mainCateIndex] && mainCategoryList[mainCateIndex].categories && mainCategoryList[mainCateIndex].categories[subCateIndex] && mainCategoryList[mainCateIndex].categories[subCateIndex].products && mainCategoryList[mainCateIndex].categories[subCateIndex].products.map((prod, index) => {
                                                                                        if (prod) {
                                                                                            return (
                                                                                                <Col xs={6} key={index}>
                                                                                                    <Card className='cardItem position-relative w-100 h-100' >
                                                                                                        <Card.Body className='p-2 text-decoration-none d-flex flex-column justify-content-between'>
                                                                                                            <div className='position-relative mb-2'>
                                                                                                                <Image width='100%' height='100%' className={`object-fit-cover rounded`} src={prod.icon ? (baseUrl + prod.icon) : noImage} onClick={() => getProductDetails(prod)} />
                                                                                                                <div className='position-absolute bottom-0 end-0 mb-1 me-1'>
                                                                                                                    {prod.qty && prod.qty > 0 === true ?
                                                                                                                        <>
                                                                                                                            <Card className={`card-item addQty_card ${prod.qty && prod.qty > 0 ? '' : 'bg-light-grey'}`}>
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(prod)}>
                                                                                                                                    <i class="fas fa-minus"></i>
                                                                                                                                </Button>
                                                                                                                                <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={prod.qty} disabled={true} />
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(prod)}>
                                                                                                                                    <i class="fas fa-plus"></i>
                                                                                                                                </Button>
                                                                                                                            </Card>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <Button style={{ padding: '.42rem .7rem' }} className=' light-btn bg-white  text-blue fs-12 rounded border-secondary-subtle' onClick={() => addProductToCart(prod)}>
                                                                                                                            <i class="fa-solid fa-cart-shopping"></i>
                                                                                                                        </Button>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=''>
                                                                                                                <div className=''>
                                                                                                                    <h5 className='fs-12 mb-0 text-truncate'>{prod.prodAlias}</h5>
                                                                                                                    <span className='fs-14 mb-0 fw-bold'>
                                                                                                                        <i class="fas fa-indian-rupee-sign text-blue"></i>{prod.price}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                {/* <div className='text-end'>
                                                                                                                    <Button variant='primary' className=' border-0 bg-transparent text-blue fs-12  p-0' >
                                                                                                                        More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                                                                                    </Button>
                                                                                                                </div> */}
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </Row>
                                                                            </Col>
                                                                            {/* <Col xs={12} >
                                                                                <div class="accordion" id="accordionExample">
                                                                                    {mainCategoryList && mainCategoryList.length > 0 && mainCategoryList[mainCateIndex] && mainCategoryList[mainCateIndex].categories && mainCategoryList[mainCateIndex].categories[subCateIndex] && mainCategoryList[mainCateIndex].categories[subCateIndex].products && mainCategoryList[mainCateIndex].categories[subCateIndex].products.map((prod, index) => {
                                                                                        if (prod) {
                                                                                            return (
                                                                                                <div className="accordion-item new_accordion" key={index}>
                                                                                                    <h2 className="accordion-header" id={"headingOne" + index}>
                                                                                                        <Image className={`object-fit-cover rounded me-3 accor_img ${expandedRow.index !== index ? '' : 'accor_img_lg'}`} src={prod.icon ? (baseUrl + prod.icon) : noImage} />
                                                                                                        {expandedRow.index !== index ?
                                                                                                            <>
                                                                                                                <span className='accor_text_one text-truncate'>{prod.prodAlias}</span>
                                                                                                                <span className='accor_price text-start text-truncate'>
                                                                                                                    {prod.qty && prod.qty > 0 === true ?
                                                                                                                        <>
                                                                                                                            <Card className={`card-item addQty_card mx-auto ${prod.qty && prod.qty > 0 ? '' : 'bg-light-grey'}`}>
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(prod)}>
                                                                                                                                    <i class="fas fa-minus"></i>
                                                                                                                                </Button>
                                                                                                                                <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={prod.qty} />
                                                                                                                                <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(prod)}>
                                                                                                                                    <i class="fas fa-plus"></i>
                                                                                                                                </Button>
                                                                                                                            </Card>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <Button style={{ padding: '.42rem .5rem' }} className='border bg-transparent text-dark fs-12 rounded d-block mx-auto addQty_card border-secondary-subtle' onClick={() => addProductToCart(prod)}>
                                                                                                                            Add <i class="fas fa-indian-rupee-sign text-blue ms-1"></i>{prod.price}
                                                                                                                        </Button>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <Row className='g-0 w-100'>
                                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                                    <span className='fs-14 mb-0 text-truncate accor_text_two'>
                                                                                                                        {prod.prodAlias}
                                                                                                                    </span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        }
                                                                                                        <button className={`accordion-button arrow_btn ${expandedRow.index !== index ? "collapsed" : ''}`} disabled={expandedRow.disable} type="button" onClick={() => handleArrowBtn(index)} data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded={expandedRow.index === index ? "true" : "false"} aria-controls={"collapseOne" + index}>
                                                                                                        </button>
                                                                                                    </h2>
                                                                                                    <div id={"collapseOne" + index} className={`accordion-collapse collapse ${expandedRow.index === index ? "show" : ""}`} aria-labelledby={"headingOne" + index} data-bs-parent="#accordionExample">
                                                                                                        <div className="accordion-body">
                                                                                                            <Row className='g-2'>
                                                                                                                <Col xs={12} sm={12} className='position-relative'>
                                                                                                                    <span className='fs-14 d-flex flex-wrap align-items-end'>
                                                                                                                        <span className='me-auto pe-3 d-flex align-items-center'>

                                                                                                                            <Card className={`card-item addQty_card ${prod.qty && prod.qty > 0 === true ? '' : 'bg-light-grey'}`}>
                                                                                                                                {prod.qty && prod.qty > 0 === true ?
                                                                                                                                    <>
                                                                                                                                        <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(prod)}>
                                                                                                                                            <i class="fas fa-minus"></i>
                                                                                                                                        </Button>
                                                                                                                                        <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={prod.qty} />
                                                                                                                                        <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(prod)}>
                                                                                                                                            <i class="fas fa-plus"></i>
                                                                                                                                        </Button>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <Button className='border-0 bg-transparent text-dark fs-12 w-100' onClick={() => addProductToCart(prod)}>
                                                                                                                                        <i class="fas fa-plus fs-12"></i> Add
                                                                                                                                    </Button>
                                                                                                                                }
                                                                                                                            </Card>
                                                                                                                            <span className="fs-14 mb-0 fw-bold ms-3">
                                                                                                                                <i class="fas fa-indian-rupee-sign text-blue"></i>{prod.price}
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                        <span className='ms-2 ms-auto'>
                                                                                                                            <Button variant='primary' className=' border-0 bg-transparent text-blue fs-12  p-0' onClick={() => getProductDetails(prod)}>
                                                                                                                                More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                                                                                            </Button>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </div>
                                                                            </Col> */}

                                                                        </Row>
                                                                    }
                                                                </> :
                                                                <>
                                                                    <Placeholder animation="glow">
                                                                        <Row className='g-2'>
                                                                            {Array(8).fill(null).map((_, idx) => {
                                                                                return (
                                                                                    <Col xs={6}>
                                                                                        <Placeholder xs={12} className='rounded' style={{ height: '216px' }} />
                                                                                    </Col>
                                                                                )
                                                                            })}
                                                                        </Row>

                                                                        {/* {Array(8).fill(null).map((_, idx) => {
                                                                            return (
                                                                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '60px' }} />
                                                                            )
                                                                        })} */}
                                                                    </Placeholder>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col >
                                            </Row >
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card >
                        </Col >
                    </Row >

                    <>
                        <div className={`toast-fade toast-backdrop ${toastFade === true ? 'show' : ''}`}></div>
                        <ToastContainer className={`toast-position ${toastFade === true ? 'toast-bg' : ''}`} position='bottom-center' >
                            <Row className=''>
                                <Col xs={12} lg={6} className='mx-auto'>
                                    {dataLoaded === true ?
                                        (store.instantSupply && store.instantSupply !== 'N') && cartList.length > 1 ?
                                            <Button className='toast_expand_btn rounded' onClick={onOpenToastButton} data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                {toastFade === true && (!store.instantSupply || store.instantSupply === 'N') ? 'close' : `(${cartList.length - 1}) more`}
                                                {toastFade === true ? <i className="fas fa-caret-down ms-1" ></i> : <i className="fas fa-caret-up ms-1" ></i>}
                                            </Button> :
                                            null
                                        // cartList.length > 1 ?
                                        //     toastFade === true ?
                                        //         <Button className='toast_expand_btn rounded' onClick={onOpenToastButton} data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        //             close {toastFade === true ? <i class="fas fa-caret-down ms-1" ></i> : <i class="fas fa-caret-up ms-1" ></i>}
                                        //         </Button> :
                                        //         <Button className='toast_expand_btn rounded' onClick={onOpenToastButton} data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        //             ({(cartList.length - 1)}) more {toastFade === true ? <i class="fas fa-caret-down ms-1" ></i> : <i class="fas fa-caret-up ms-1" ></i>}
                                        //         </Button> :
                                        //     null
                                        :
                                        store.instantSupply && store.instantSupply !== 'N' && cartList && cartList.length > 0 ?
                                            <Placeholder animation="glow"  >
                                                <Placeholder className='rounded toast_expand_btn' style={{ width: '70px', height: '23px' }} />
                                            </Placeholder> : null}
                                    <div class="collapse" id="collapseExample">
                                        <h5 className='fs-14 fw-bold mb-2'>Your Carts ({cartList.length})</h5>
                                        {store.instantSupply && store.instantSupply !== 'N' && cartList && cartList.length > 0 ?
                                            cartList.map((ordr, index) => {
                                                return (
                                                    <Toast key={index} className="shadow-sm bg-light-yellow rounded w-100 mb-2" delay={3000} >
                                                        <Toast.Body className='py-2' >
                                                            <Row className='g-0'>
                                                                <Col xs={12}>
                                                                    <span className='d-flex flex-wrap align-items-center'>
                                                                        <div className='me-auto pe-3 d-flex align-items-center'>
                                                                            <span className='toast_icon text-blue'>
                                                                                {ordr && ordr.id ?
                                                                                    <i class="fas fa-history"></i> :
                                                                                    <i class="fas fa-cart-shopping"></i>
                                                                                }
                                                                            </span>
                                                                            <div className=''>
                                                                                {ordr.id && ordr.id ?
                                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                                        {ordr?.store?.name}
                                                                                    </h4> :
                                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                                        {store.name}
                                                                                    </h4>
                                                                                }
                                                                                <div className='d-flex align-items-center'>
                                                                                    <span className="fs-12">{ordr.items.length} items</span>
                                                                                    <div className='divider' style={{ borderColor: '#00000099' }}></div>
                                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                                        <i class="fas fa-indian-rupee-sign"></i>{ordr.orderTotal}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {ordr && ordr.id ?
                                                                            <Button className='bg-transparent border-0 shadow-none text-dark ms-auto p-0 fs-14 lh-1' onClick={() => navigate('/orderhistory')} role='button'>
                                                                                Order Details < i class="fas fa-caret-right ms-1" ></i>
                                                                            </Button> :
                                                                            <Button className='bg-transparent border-0 shadow-none text-dark ms-auto p-0 fs-14 lh-1' onClick={() => navigate('/cart')} role='button'>
                                                                                View Cart < i class="fas fa-caret-right ms-1" ></i>
                                                                            </Button>
                                                                        }
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </Toast.Body>
                                                    </Toast>
                                                )
                                            })
                                            : null
                                        }
                                    </div>
                                    {dataLoaded === true ?
                                        <>
                                            {prepareOrder.items && prepareOrder.items.length > 0 && toastFade === false ?
                                                <Toast className="shadow-sm bg-light-yellow rounded w-100" delay={3000} >
                                                    <Toast.Body className='py-2' >
                                                        <Row className='g-0'>
                                                            <Col xs={12}>
                                                                <span className='d-flex flex-wrap align-items-center'>
                                                                    <div className='me-auto pe-3 d-flex align-items-center'>

                                                                        <span className='toast_icon text-blue'>
                                                                            {prepareOrder && prepareOrder.id ?
                                                                                <i class="fas fa-history"></i> :
                                                                                <i class="fas fa-cart-shopping"></i>
                                                                            }
                                                                        </span>
                                                                        <div className=''>
                                                                            <h4 className="fs-14 fw-bold mb-0">
                                                                                {store.name}
                                                                            </h4>
                                                                            <div className='d-flex align-items-center'>
                                                                                <span className="fs-12">{orderedItemsQty} items</span>
                                                                                <div className='divider' style={{ borderColor: '#00000099' }}></div>
                                                                                <h4 className="fs-14 fw-bold mb-0">
                                                                                    <i class="fas fa-indian-rupee-sign"></i>{prepareOrder.orderTotal}
                                                                                </h4>
                                                                            </div>
                                                                        </div>


                                                                        {/* <div className=''>
                                                                    <span className="fs-12">{orderedItemsQty} items</span>
                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                        <i class="fas fa-indian-rupee-sign fs-12"></i>{prepareOrder.orderTotal}
                                                                    </h4>
                                                                </div> */}
                                                                    </div>
                                                                    {prepareOrder && prepareOrder.id ?
                                                                        <Button className='bg-transparent border-0 shadow-none text-dark ms-auto p-0 fs-14 lh-1' onClick={() => navigate('/orderhistory')} role='button'>
                                                                            Order Details < i class="fas fa-caret-right ms-1" ></i>
                                                                        </Button> :
                                                                        <Button className='bg-transparent border-0 shadow-none text-dark ms-auto p-0 fs-14 lh-1' onClick={() => navigate('/cart')} role='button'>
                                                                            View Cart < i class="fas fa-caret-right ms-1" ></i>
                                                                        </Button>}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Toast.Body>
                                                </Toast> :
                                                <>
                                                    {store.instantSupply && store.instantSupply !== 'N' && cartList && cartList.length > 0 ?
                                                        cartList.map((ordr, index) => {
                                                            if (index == 0 && toastFade == false) {
                                                                return (
                                                                    < Toast className="shadow-sm bg-light-yellow rounded w-100 mb-2" delay={3000} >
                                                                        <Toast.Body className='py-2' >
                                                                            <Row className='g-0'>
                                                                                <Col xs={12}>
                                                                                    <span className='d-flex flex-wrap align-items-center'>
                                                                                        <div className='me-auto pe-3 d-flex align-items-center'>
                                                                                            <span className='toast_icon text-blue'>
                                                                                                {ordr && ordr.id ?
                                                                                                    <i class="fas fa-history"></i> :
                                                                                                    <i class="fas fa-cart-shopping"></i>
                                                                                                }
                                                                                            </span>
                                                                                            <div className=''>
                                                                                                {ordr.id && ordr.id ?
                                                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                                                        {ordr?.store?.name}
                                                                                                    </h4> :
                                                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                                                        {store.name}
                                                                                                    </h4>
                                                                                                }
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <span className="fs-12">{ordr.items.length} items</span>
                                                                                                    <div className='divider' style={{ borderColor: '#00000099' }}></div>
                                                                                                    <h4 className="fs-14 fw-bold mb-0">
                                                                                                        <i class="fas fa-indian-rupee-sign"></i>{ordr.orderTotal}
                                                                                                    </h4>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {ordr && ordr.id ?
                                                                                            <Button className='bg-transparent border-0 shadow-none text-dark ms-auto p-0 fs-14 lh-1' onClick={() => moveToOrderSummaryPage(ordr)} role='button'>
                                                                                                Order Details < i class="fas fa-caret-right ms-1" ></i>
                                                                                            </Button> :
                                                                                            <Button className='bg-transparent border-0 shadow-none text-dark ms-auto p-0 fs-14 lh-1' onClick={() => navigate('/cart')} role='button'>
                                                                                                View Cart < i class="fas fa-caret-right ms-1" ></i>
                                                                                            </Button>
                                                                                        }
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Toast.Body>
                                                                    </Toast>
                                                                )
                                                            }
                                                        })
                                                        : null
                                                    }

                                                </>}
                                        </> :
                                        prepareOrder.items.length > 0 ?
                                            < Placeholder animation="glow"  >
                                                <Placeholder xs={12} className='rounded' style={{ height: '56px' }} />
                                            </Placeholder> : null}
                                </Col>
                            </Row>
                        </ToastContainer>
                    </>
                </Col >
            </Row >

            <FilterModal showModal={showFilterModal} hideModal={() => setShowFilterModal(false)} handleSubmit={onFilterSubmit} filters={searchBy} />
            < BrandModal showBrand={showBrand} onCloseButton={onCloseButton} onCloseBrandModal={onCloseBrandModal} />

            <MapModal showMap={showMap} onCloseMapButton={onCloseMapButton} />

            <SwitchBrandVerifierModal show={showBrandSwitchConfirmationModal} onCloseButton={confirmOrderSwitch} />

            <DineModal showModal={showModal} onCloseModalBtn={onCloseModalBtn} switchOrderType={switchOrderType} deliveryMethods={deliveryMethods} orderDetails={prepareOrder} />

        </>
    )
}




export default (Home);
